<template>
    <div class='userComplaintListAl'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <!-- <el-input style="width:200px;" v-model="paginationInfo.condition.mobile" maxlength="11"
                        placeholder="请输入手机号" clearable></el-input> -->
                    <el-date-picker v-model="paginationInfo.condition.begin_date" align="right" type="date"
                        placeholder="选择开始时间" :picker-options="{
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                }">
                    </el-date-picker>
                    <el-date-picker v-model="paginationInfo.condition.end_date" align="right" type="date"
                        placeholder="选择结束时间" :picker-options="{
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                }">
                    </el-date-picker>
                    <!-- <el-select v-model="paginationInfo.condition.app_code" placeholder="请选择订单状态" @change="queryList"
                        filterable clearable>
                        <el-option label="language会员" value="languagemember"></el-option>
                        <el-option label="冰蓝科技" value="blkjwechatpay"></el-option>
                        <el-option label="陀螺领购" value="tllgwechatpay"></el-option>
                    </el-select> -->
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="mobile" label="用户手机号" align='center' width="150">
                </el-table-column>
                <el-table-column label="投诉时间" align='center' width="150">
                    <template slot-scope="scope">
                        {{ $moment(scope.row.create_date).format('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="投诉内容" align='center'>
                </el-table-column>
                <el-table-column prop="desc" label="投诉描述" align='center'>
                </el-table-column>
                <el-table-column label="投诉图片" align='center'>
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.screenshots[0]"
                            :preview-src-list="scope.row.screenshots">
                        </el-image>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="投诉状态" align='center' width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.complaint_state == 'PROCESSING'">处理中</span>
                        <span v-else-if="scope.row.complaint_state == 'PENDING'">待处理</span>
                        <span v-else-if="scope.row.complaint_state == 'PROCESSED'">处理完</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align='center' width="150">
                    <template slot-scope="scope">
                        <el-button type="primary" plain :disabled="scope.row.complaint_state == 'PROCESSED'"
                            @click="handleTk({ out_trade_no: scope.row.complaint_order_info[0].out_trade_no, complaint_id: scope.row.complaint_id })">退款</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isHandleL: false,
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    begin_date: "",
                    end_date: "",
                    // app_code: "languagemember",
                    // mobile: '',
                    // status: '',
                }
            },
            tableData: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        this.paginationInfo.condition.begin_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.paginationInfo.condition.end_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.getUserComplaints();
    },
    mounted() {
    },
    methods: {
        handleTk(info) {
            this.$confirm('是否确认退款？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.isHandleL = true;
                this.$service.OnlineOrderService
                    .handleTk(info)
                    .then(res => {
                        this.isHandleL = false;
                        if (res.data.code === 0) {
                            this.getUserComplaints();
                            this.$message({
                                message: '退款成功！',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: 'error'
                            });
                        }
                    }).catch((err) => {
                        this.isHandleL = false;
                        window.console.log(err)
                        this.$message({
                            message: '网络错误，请重新修改！',
                            type: 'error'
                        });
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        queryList() {
            this.paginationInfo.pageNo = 1;
            this.getUserComplaints()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getUserComplaints();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getUserComplaints();
        },
        getUserComplaints() {
            this.$service.OnlineOrderService
                .getUserComplaints(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data;
                        this.paginationInfo.total = dataInfo.total || 1000;
                        this.tableData = dataInfo.complaints;
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>

<style lang='less' scoped>
.userComplaintListAl {}
</style>